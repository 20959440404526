<template>
  <div class="copyright-practice-index">
    <div class="copyright-practice-index-content">
      <!--搜索框-->
      <!-- <CopyrightSearch /> -->
      <!--banner图-->
      <CopyrightBanner :images="bannerList" />
      <!--排序-->
      <!-- <CopyrightSortBox :num="num" :item-list="sortLang" /> -->
      <!--产品-->
      <div class="copyright-practice-index-content-products">
        <div
          class="products-panel"
          v-for="(item, index) in productList"
          :key="index"
        >
          <div class="products-panel-top">
            <div class="products-panel-top-title">
              <span>{{ item.cateName }}</span>
              <CopyrightSlideBtn />
            </div>
            <!-- <div class="products-panel-top-more">更多&gt;&gt;</div> -->
          </div>
          <div class="products-panel-body">
            <div
              class="products-panel-body-group"
              v-for="(group, groupIndex) in item.products"
              :key="groupIndex"
            >
              <template v-if="item.cateType == 1">
                <CopyrightProductItem
                  v-for="(product, productIndex) in group"
                  :key="productIndex"
                  :product-info="product"
                  @click.native="openDetail(product.id)"
                />
              </template>
              <template v-if="item.cateType == 2">
                <CopyrightBlogItem
                  v-for="(blog, blogIndex) in group"
                  :key="blogIndex"
                  :item-info="blog"
                  @click.native="openDetail(blog.id)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyrightSearch from "components/copyright-service/CopyrightSearch";
import CopyrightBanner from "components/copyright-service/CopyrightBanner";
import CopyrightSortBox from "components/copyright-service/CopyrightSortBox";
import CopyrightProductItem from "components/copyright-service/CopyrightProductItem";
import CopyrightBlogItem from "components/copyright-service/CopyrightBlogItem";
import CopyrightSlideBtn from "components/copyright-service/CopyrightSlideBtn";
import { page } from "api/copyrightService";
import { chunk } from "utils/utils";
export default {
  name: "CopyrightPracticeIndex",
  data() {
    return {
      //广告图
      bannerList: [
        {
          id: 0,
          imgUrl: require("assets/images/exclusive-registration/slideshow.png"),
        },
        // {
        //   id: 1,
        //   imgUrl: require("assets/images/slideshow1.png"),
        // },
      ],
      //排序列表语言
      sortLang: [
        {
          name: "综合",
        },
        {
          name: "销量",
        },
        {
          name: "新品",
        },
        {
          name: "价格",
        },
        // {
        //   name: "评论数",
        // },
      ],
      //产品列表
      productList: [
        {
          cateName: "自助指引",
          cateType: 1,
          products: [],
        },
      ],
      // 商品数量
      num: "",
    };
  },
  methods: {
    openDetail(id) {
      this.$router.push({
        name: "CopyrightPracticeDetail",
        params: { id },
      });
    },
    async getlist() {
      const data = {
        page: 1,
        limit: 10,
        type: 2,
      };

      const res = await page(data);
      if (res.code === 200) {
        this.productList[0].products = chunk(res.data.data, 4);
        this.num = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: "请求失败",
        });
      }
    },
  },
  components: {
    CopyrightBanner,
    CopyrightProductItem,
    CopyrightBlogItem,
    CopyrightSlideBtn,
  },
  created() {
    this.getlist();
  },
};
</script>

<style lang="scss" scoped>
.copyright-practice-index {
  &-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 68px 40px;

    .searchBox {
      width: 100%;
      justify-content: space-between;
    }
    &-products {
      margin-top: 20px;
      .products-panel {
        &-top {
          @include flex-between;
          &-title {
            @include flex-start;
            > span {
              margin-right: 29px;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
          }
          &-more {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            cursor: pointer;
          }
        }
        &-body {
          &-group {
            @include flex-start;
            flex-wrap: wrap;
            flex-shrink: 0;
            .products-item {
              margin-top: 19px;
            }
            .blog-item {
              margin-top: 19px;
            }
            > div:first-child ~ div {
              margin-left: 24px;
            }
          }
        }
      }
      .products-panel:first-child ~ .products-panel {
        margin: 50px 0;
      }
    }
  }
}
</style>
